import * as React from "react";
import { Text, TypographyProps } from "../../../atoms/typography";
import { Responsive } from "../../../gls/responsive";
import { StretchSpacer } from "../../../gls/spacer";

export const SectionIntroLayout = (props: {
  left: React.ReactNode;
  right: React.ReactNode;
  bottom?: React.ReactNode;
}) => (
  <>
    <Responsive>
      {props.left}
      <StretchSpacer />
      {props.right}
    </Responsive>
    {props.bottom && <>{props.bottom}</>}
  </>
);

export const SectionIntroHeading = (props: TypographyProps) => (
  <Text fontSize={"lg"} fontWeight={"book"} fontFamily={"monument"} textTransform={"uppercase"} {...props} />
);
